import React, { useEffect, useState } from 'react'

import Container from 'react-bootstrap/Container'
import ErrorMessage from '../../components/error-message'
import OrderData from './order-data'
import OrderHead from './order-head'
import Row from 'react-bootstrap/Row'

import { get } from '../../components/requests'

function extractErrorMessages (message) {
  return ('errors' in message)
    ? message.errors.join(', ')
    : 'Error'
}

async function post (setOrder, setError, setIsLoaded, url, body) {
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'text/plain'
      },
      body: body
    })
    if (res.ok) {
      const order = await res.json()
      get(
        `${process.env.REACT_APP_API_HOST}/orders/${order.id}`,
        setError,
        setIsLoaded,
        setOrder
      )
    } else {
      setError(extractErrorMessages(await res.json()))
    }
  } catch {
    setError('Error')
  }
}

export default function ViewOrder ({ match }) {
  const id = match.params.id
  const [order, setOrder] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  function onAction (url, body) {
    post(setOrder, setError, setIsLoaded, url, body)
  }

  useEffect(() => {
    get(
      `${process.env.REACT_APP_API_HOST}/orders/${id}`,
      setError,
      setIsLoaded,
      setOrder
    )
  }, [id])

  return isLoaded
    ? (
      <Container>
        {!error && <Row><OrderHead {...order} /></Row>}
        {!error
          ? <OrderData order={order} onAction={onAction} /> // eslint-disable-line react/jsx-no-bind
          : <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    ) : null
}
