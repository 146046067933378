import React, { useEffect, useReducer, useContext } from 'react'

import Container from 'react-bootstrap/Container'
import ErrorMessage from '../../components/error-message'
import OrderForm from './order-form'
import OrderHead from './order-head'
import Row from 'react-bootstrap/Row'

import { get, post } from '../../components/requests'

import reducer from './order-reducer'
import initalState from './order-inital-state'
import UserContext from '../../components/user-context'

function postOrder (history, isTest, order, resource, setError) {
  order.resource = resource._links.self.href
  order.test = isTest
  post(
    history,
    `${process.env.REACT_APP_API_HOST}/orders`,
    JSON.stringify(order),
    '/orders',
    setError
  )
}

export default function CreateOrder ({ history, location, match }) {
  const user = useContext(UserContext)
  const [state, dispatch] = useReducer(reducer, initalState({ location, match, user }))

  const setError = (error) => {
    dispatch({
      type: 'fetch-error',
      payload: {
        error
      }
    })
  }

  useEffect(() => {
    if (!user.init && !state.usedUser) {
      dispatch({
        type: 'user-ready',
        payload: {
          user
        }
      })
    }

    get(
      `${process.env.REACT_APP_API_HOST}/resources/${state.resourceId}`,
      setError,
      () => {
        dispatch({
          type: 'loaded'
        })
      },
      resource => {
        dispatch({
          type: 'resource-response',
          payload: {
            resource
          }
        })
      }
    )
  }, [state.resourceId, user, state.usedUser])

  return state.loaded
    ? (
      <Container>
        {!state.error && <Row><OrderHead isTest={state.isTest} resource={state.resource} /></Row>}
        {!state.error
          ? state.resource.enabled
            ? (
              <OrderForm
                resource={state.resource}
                form={state.form}
                onUpdate={(payload) => {
                  dispatch({
                    type: 'form-update',
                    payload
                  })
                }}
                onSubmit={(order) => postOrder(history, state.isTest, order, state.resource, setError)}
              />
            )
            : <ErrorMessage>This dataset has been disabled and cannot be downloaded</ErrorMessage>
          : <ErrorMessage>{state.error}</ErrorMessage>}
      </Container>
    ) : null
}
