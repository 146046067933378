import React from 'react'
import { Link } from 'react-router-dom'

export default ({ id, title = '', datasetUrl = '', licence, enabled }) => (
  <tr key={id}>
    <td className='text-center'>{enabled ? 'Y' : 'N'}</td>
    <td>
      <Link to={{ pathname: `/resources/${id}` }}>{id}</Link>
    </td>
    <td>{title}</td>
    <td><a href={datasetUrl} target='_blank' rel='noopener noreferrer'>{datasetUrl.replace('https://catalogue.ceh.ac.uk/documents/', '')}</a></td>
    <td>
      {licence !== undefined && <a href={licence.url}>{licence.name}</a>}
    </td>
  </tr>
)
