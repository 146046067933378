export default async (onUser, onError) => {
  const res = await fetch('/api/user')
  if (res.ok) {
    const user = await res.json()
    user.isAdminOrLicensing = (user.roles.includes('ordermanager-admin') || user.roles.includes('data-licensing'))
    onUser(user)
  } else {
    onError()
  }
}
