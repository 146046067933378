import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import UserContext from '../../components/user-context'

export default ({ id, state, resourceTitle, placed, username, test }) => {
  const user = useContext(UserContext)
  return (
    <tr
      key={id}
      className={`order-row ${state === 'IN_ERROR' ? 'bg-danger text-white' : ''}`}
    >
      <td>{new Date(placed).toISOString().split('T')[0]}</td>
      <td>{state}</td>
      <td>
        <Link
          className={state === 'IN_ERROR' ? 'text-white' : ''}
          to={{ pathname: `/orders/${id}` }}
        >
          {id}
        </Link>
      </td>
      <td title={resourceTitle}>{resourceTitle}</td>
      {
        user.isAdminOrLicensing &&
          <td>{username}</td>
      }
      {
        user.isAdminOrLicensing &&
          <td className='text-center'>
            {test ? 'Y' : null}
          </td>
      }
    </tr>
  )
}
