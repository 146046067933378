import React, { useEffect, useState, createContext } from 'react'
import getUser from './navbar/user'

const Context = createContext({})

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ init: true, loggedIn: false, roles: [], isAdminOrLicensing: false })

  useEffect(() => {
    if (user.init) {
      getUser(
        (user) => {
          setUser({
            ...user,
            init: false,
            loggedIn: true
          })
        },
        () => {
          setUser({
            ...user,
            init: false,
            loggedIn: false
          })
        }
      )
    }
  }, [user])

  return (
    <Context.Provider value={user}>
      {children}
    </Context.Provider>
  )
}

export { UserProvider }

export default Context
