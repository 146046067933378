import React, { useEffect, useReducer } from 'react'

import { Container, Button } from 'react-bootstrap'
import Pagination from '../../components/pagination'
import initialState from './table-initial-state'
import fetchRows from '../../fetch/rows'
import reducer from './table-reducer'
import Table from '../../components/table/table'

import OrdersHead from './orders-head'
import OrderRow from './order-row'
import ResourcesHead from './resources-head'
import ResourceRow from './resource-row'

export default ({ match, history }) => {
  const root = match.params.root
  const [state, dispatch] = useReducer(reducer(root), initialState(root))
  if (root !== state.root) state.rows = []
  useEffect(() => {
    if (root !== state.root) dispatch({ type: 'reroute', payload: { root } })
    if (navigator.userAgent !== 'ReactSnap' && state.loading) fetchRows(state, dispatch, root)
  }, [root, state])

  const Head = root === 'orders' ? OrdersHead : ResourcesHead
  const Row = root === 'orders' ? OrderRow : ResourceRow

  return (
    <Container>
      <h3>{root === 'orders' ? 'Orders' : 'Resources'}</h3>
      {root === 'resources' ? <Button onClick={() => history.push('/resources/new')}>Create new Resource</Button> : null}
      <Pagination
        page={state.page.number}
        total={state.page.total}
        perPage={`${state.page.size} ${root} per page`}
        onPageChange={(value) => dispatch({
          type: 'page-change',
          payload: {
            page: value
          }
        })}
      />
      <Table
        head={(
          <Head
            state={state}
            onChange={(change) => dispatch({
              type: 'filter',
              payload: { change }
            })}
          />)}
        body={state.rows.map(Row)}
        error={state.error ? 'AN ERROR OCCURED' : null}
        colSpan={6}
      />
    </Container>
  )
}
