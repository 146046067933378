import React from 'react'

import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export default function SuccessMaessage ({ children }) {
  return (
    <Form.Text className='text-success resource-form-message'>
      <i className='resource-form-message-icon'>
        <FontAwesomeIcon icon={faCheck} />
      </i>
      {children}
    </Form.Text>
  )
}
