import React from 'react'

import Radio from '../../components/radio'

export default function OrderRadio ({ name, label, checkedName, onChange, disabled, labelInfo }) {
  return (
    <Radio
      onChange={() => onChange(name)}
      checked={checkedName === name}
      name={name}
      label={label}
      disabled={disabled}
      labelInfo={labelInfo}
    />
  )
}

OrderRadio.defaultProps = {
  disabled: false
}
