import React from 'react'

import { Table } from 'react-bootstrap'

export default (props) => {
  const {
    head,
    body,
    error,
    colSpan = 6
  } = props

  const tableProps = Object.assign({}, props)
  delete tableProps.head
  delete tableProps.body
  delete tableProps.error
  delete tableProps.colSpan

  return (
    <Table
      striped
      responsive
      {...tableProps}
    >
      <thead>
        {head}
      </thead>
      <tbody>
        {error
          ? (
            <tr>
              <td className='text-danger text-center' colSpan={colSpan}>{error}</td>
            </tr>
          )
          : body}
      </tbody>
    </Table>

  )
}
