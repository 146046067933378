import React, { useContext } from 'react'
import HeadCheckbox from '../../components/table/head-checkbox'
import HeadDateRange from '../../components/table/head-date-range'
import HeadInput from '../../components/table/head-input'
import HeadSelect from '../../components/table/head-select'
import UserContext from '../../components/user-context'

export default ({ state, onChange }) => {
  const user = useContext(UserContext)
  return (
    <tr>
      <HeadDateRange
        label='Date'
        start={state.filter.orders.placed.start}
        end={state.filter.orders.placed.end}
        direction={state.sort.orders.placed}
        onDirectionChange={(direction) => {
          onChange({
            field: 'placed',
            sort: direction
          })
        }}
        onChange={({ start, end }) => {
          onChange({
            field: 'placed',
            value: {
              start,
              end
            }
          })
        }}
      />
      <HeadSelect
        className='order-state'
        values={[
          { value: 'ALL', label: 'All' },
          { value: 'COMPLETED', label: 'Completed' },
          { value: 'REJECTED', label: 'Rejected' },
          { value: 'IN_ERROR', label: 'In Error' },
          { value: 'PLACED', label: 'Placed' },
          { value: 'PROCESSING', label: 'Processing' }
        ]}
        value={state.filter.orders.state}
        label='State'
        direction={state.sort.orders.state}
        onDirectionChange={(direction) => {
          onChange({
            field: 'state',
            sort: direction
          })
        }}
        onChange={(evt) => {
          onChange({
            field: 'state',
            value: evt.target.value
          })
        }}
      />
      <HeadInput
        className='order-number'
        label='Order Number'
        direction={state.sort.orders.id}
        onDirectionChange={(direction) => {
          onChange({
            field: 'id',
            sort: direction
          })
        }}
        onChange={(evt) => {
          onChange({
            field: 'id',
            value: evt.target.value
          })
        }}
      />
      <HeadInput
        label='Dataset'
        direction={state.sort.orders.resourceTitle}
        onDirectionChange={(direction) => {
          onChange({
            field: 'resourceTitle',
            sort: direction
          })
        }}
        onChange={(evt) => {
          onChange({
            field: 'resourceTitle',
            value: evt.target.value
          })
        }}
      />
      {
        user.isAdminOrLicensing &&
          <HeadInput
            className='order-username'
            label='User'
            direction={state.sort.orders.username}
            onDirectionChange={(direction) => {
              onChange({
                field: 'username',
                sort: direction
              })
            }}
            onChange={(evt) => {
              onChange({
                field: 'username',
                value: evt.target.value
              })
            }}
          />
      }{
        user.isAdminOrLicensing &&
          <HeadCheckbox
            label='Test'
            checked={state.filter.orders.test}
            onChange={(evt) => {
              onChange({
                field: 'test',
                value: evt.target.checked
              })
            }}
          />
      }
    </tr>
  )
}
