import React, { useEffect, useState } from 'react'

import AreaOfInterest from './area-of-interest'
import Boundary from './boundary'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import LicenceAgreement from './licence-agreement'
import LicenceInfoForm from './licence-info-form'
import OrderMap from './order-map'
import Row from 'react-bootstrap/Row'
import SubmitButton from '../../components/submit-button'

import checkOrderForm from './check-order-form'

const CLIP_BOUNDARIES = 'om:clipBoundaries'

async function getBoundaries (resource, setBoundaries) {
  if (CLIP_BOUNDARIES in resource._links) {
    const response = await fetch(resource._links[CLIP_BOUNDARIES].href)
    if (response.ok) {
      setBoundaries(await response.json())
    }
  }
}

const canSubmit = (resource, boundary, spatialFilterType, form, formErrors) => {
  const isAcademic = form.licenceInformation.type === 'ACADEMIC'

  const academicFields = Object.keys(formErrors.licenceInformation.details).filter(key => !['intendedUse'].includes(key))
  const hasAcademicErrors = isAcademic && academicFields.length > 0

  const nonAcademicFields = Object.keys(formErrors.licenceInformation.details).filter(key => ['intendedUse'].includes(key))
  const hasNonAcademicFields = nonAcademicFields.length > 0

  const hasContactErrors = Object.keys(formErrors.licenceInformation.contact).length > 0

  let hasValidBoundary = true
  if (spatialFilterType === 'PREDEFINED') hasValidBoundary = (boundary !== undefined && boundary !== '')
  if (spatialFilterType === 'CLIPPED') hasValidBoundary = (form.wkt !== undefined && form.wkt !== '')

  const validForm = !hasContactErrors &&
  !hasNonAcademicFields &&
  !hasAcademicErrors

  if (!('licence' in resource)) return hasValidBoundary && validForm && !('agree' in formErrors.licenceInformation)
  return hasValidBoundary
}

export default function OrderForm ({ resource, onSubmit, form, onUpdate }) {
  const formErrors = checkOrderForm(form)

  const [boundaries, setBoundaries] = useState([])
  useEffect(() => {
    if (boundaries.length === 0) getBoundaries(resource, setBoundaries)
  }, [boundaries, resource])

  function submitOrder () {
    onSubmit({
      boundary: form.boundary,
      licenceInformation: form.licenceInformation,
      parameters: {
        SpatialFilterType: form.spatialFilterType,
        WellKnownTextInEPSG3857: form.wkt,
        Clipper: form.boundary
      }
    })
  }

  return (
    <Form>
      <Row>
        <Col md={4} className='form-section'>
          <div className='form-section'>
            <AreaOfInterest
              hasBoundaries={boundaries.length > 0}
              spatialFilterType={form.spatialFilterType}
              onChange={(spatialFilterType) => {
                onUpdate({
                  spatialFilterType,
                  wkt: undefined,
                  boundary: spatialFilterType !== 'PREDEFINED' ? undefined : form.boundary
                })
              }}
            />
            <Boundary
              isPredefined={boundaries.length > 0 && form.spatialFilterType === 'PREDEFINED'}
              boundary={form.boundary}
              options={boundaries}
              onChange={(boundary) => {
                onUpdate({ boundary })
              }}
            />
          </div>
        </Col>
        <Col md={8}>
          <OrderMap
            boundingBoxes={resource.boundingBoxes}
            onChange={(wkt) => {
              onUpdate({ wkt })
            }}
            draw={form.spatialFilterType === 'CLIPPED'}
          />
          {formErrors.wkt && <Form.Text className='text-danger'>{formErrors.wkt}</Form.Text>}
        </Col>
      </Row>
      {!('licence' in resource)
        ? (
          <LicenceInfoForm
            licenceInformation={form.licenceInformation}
            errors={formErrors.licenceInformation}
            onChange={(licenceInformation) => {
              onUpdate({ licenceInformation })
            }}
          />
        )
        : <LicenceAgreement licence={resource.licence} />}
      <Form.Group className='mt-4'>
        {
          // eslint-disable-next-line react/jsx-no-bind
        }<SubmitButton onClick={submitOrder} disabled={!canSubmit(resource, form.boundary, form.spatialFilterType, form, formErrors)} />
      </Form.Group>
    </Form>
  )
}
