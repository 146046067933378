import React from 'react'

import 'leaflet'
import 'leaflet/dist/leaflet.css'
import { Polygon, Map, TileLayer } from 'react-leaflet'

import Col from 'react-bootstrap/Col'

export default function PreviewMap ({ wkt, cols }) {
  let positions = wkt.substring(wkt.indexOf('POLYGON((') + 9, wkt.indexOf(')))'))
  positions = [positions.split(',').map(item => {
    const lonLat = item.split(' ')
    return [lonLat[1], lonLat[0]]
  })]

  return (
    <Col md={cols}>
      <div className='preview-map'>
        <Map
          bounds={positions}
          boundsOptions={{ padding: [10, 10] }}
        >
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
          />
          <Polygon positions={positions} />
        </Map>
      </div>
    </Col>
  )
}
