import React, { useEffect, useState } from 'react'

import Container from 'react-bootstrap/Container'
import ErrorMessage from '../../components/error-message'
import ResourceData from './resource-data'

import { get } from '../../components/requests'

export default function ViewResource ({ match, history }) {
  const id = match.params.id
  const revision = match.params.revision
  const [resource, setResource] = useState()
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    get(
      `${process.env.REACT_APP_API_HOST}/resources/${id}`,
      setError,
      setIsLoaded,
      setResource
    )
  }, [id])

  return isLoaded
    ? (
      <Container className='resource'>
        {!error
          ? (
            <ResourceData
              history={history}
              resource={resource}
              setResource={setResource}
              setError={setError}
              revision={revision}
            />
          )
          : <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    ) : null
}
