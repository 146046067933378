import React from 'react'
import { InputGroup, FormControl } from 'react-bootstrap'
import Head from './head'
import HeadLink from './head-link'

export default ({ className, label, onChange, direction, onDirectionChange }) => (
  <Head className={`${className} head-input`}>
    <HeadLink
      label={label}
      direction={direction}
      onClick={onDirectionChange}
    />
    <InputGroup>
      <FormControl
        as='input'
        onChange={onChange}
      />
    </InputGroup>
  </Head>
)
