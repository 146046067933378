export default async (data, dispatch, history, shouldUpdate) => {
  dispatch({
    type: 'submitted'
  })

  try {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/resources/${data.id || ''}`, {
      method: shouldUpdate ? 'PATCH' : 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (res.ok) {
      const data = await res.json()
      dispatch({
        type: 'submit-response'
      })
      history.push(`/resources/${data.id}`)
    } else {
      const err = await res.json()
      dispatch({
        type: 'submit-error',
        payload: err
      })
    }
  } catch (err) {
    dispatch({
      type: 'submit-error',
      payload: err
    })
  }
}
