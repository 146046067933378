import proj4 from 'proj4'

function EPSG4326ToEPSG3857 (wkt) {
  return wkt.replace(/[-0-9.]+ [-0-9.]+/g, val => {
    return proj4('EPSG:3857', val.split(' ').map(Number)).map(a => parseInt(a)).join(' ')
  })
}

function EPSG3857ToEPSG4326 (wkt) {
  return wkt.replace(/[-0-9.]+ [-0-9.]+/g, val => {
    return proj4('EPSG:3857', 'EPSG:4326', val.split(' ').map(Number)).map(x => x.toFixed(7)).join(' ')
  })
}

export { EPSG4326ToEPSG3857, EPSG3857ToEPSG4326 }
