import debounce from 'debounce'
import get from './get'
import createFindQuery from './create-find-query'
import createSortQuery from './create-sort-query'

const fetchRows = (state, dispatch, root) => {
  const findQuery = createFindQuery(state, root)
  const sortQuery = createSortQuery(state, root)
  const path = findQuery.length === 0 ? `/${root}` : `/${root}/search/${root.replace(/s$/g, '')}`
  const url = `${process.env.REACT_APP_API_HOST}${path}?page=${state.page.number - 1}${sortQuery}${findQuery}`
  get(
    url,
    'fetch-response',
    dispatch
  )
}

export default debounce(fetchRows)
