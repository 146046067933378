export default (payload, key) => {
  const update = {}
  update.page = {
    number: payload.page.number + 1,
    size: payload.page.size,
    total: payload.page.totalPages
  }
  update.loading = false
  update.rows = payload._embedded[`om:${key}`]
  return update
}
