import React from 'react'
import FieldLink from '../../components/field-link'

const hasValidLicence = (licence = {}) => licence && licence.name && licence.url

export default ({ licence }) => hasValidLicence(licence)
  ? (
    <FieldLink
      label='Licence'
      href={licence.url}
      value={licence.name}
    />
  )
  : null
