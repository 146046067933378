import React from 'react'

import Col from 'react-bootstrap/Col'
import Field from '../../components/field'
import Row from 'react-bootstrap/Row'

const licenceTypeToLabel = {
  ACADEMIC: 'Academic research',
  COMMERCIAL: 'Internal business use',
  PERSONAL: 'Personal use',
  INNOVATION: 'Innovation',
  EDUCATIONAL: 'Educational'
}

export default (order) => {
  const licence = order.licenceInformation
  return order.requiresLicensing ? (
    <>
      <Row className='order-data-licence'>
        <h4>Licence Information</h4>
      </Row>
      <Row>
        <Col xs={4}>
          <Field label='Type' value={licenceTypeToLabel[licence.type] || licence.type} />
        </Col>
        <Col xs={4}>
          <h5>Contact</h5>
          <Field label='Name' value={licence.contact.name} />
          <Field label='Email' value={licence.contact.email} />
          <Field label='Organisation' value={licence.contact.organisation} />
          <Field label='Telephone' value={licence.contact.telephone || 'No telephone provided'} />
          <Field label='Other contact details' value={licence.contact.other} />
        </Col>
        <Col xs={4}>
          <h5>Details</h5>
          {licence.type === 'ACADEMIC' && (
            <>
              <Field label='Project name' value={licence.details.projectName} />
              <Field label='Duration' value={licence.details.duration} />
              <Field label='Funding source' value={licence.details.fundingSource || 'No funding source provided'} />
              <Field label='Used by student' value={licence.details.student ? 'Yes' : 'No'} />
            </>
          )}
          <Field label='Intended use' value={licence.details.intendedUse || 'No intended use provided'} />
        </Col>
      </Row>
    </>
  ) : null
}
