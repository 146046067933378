const toISO = (date, offset = 0) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + offset}`

export default (state, root) => {
  let find = []
  for (const key in state.filter[root]) {
    const value = state.filter[root][key]
    if (value !== '' && value !== false) {
      if (key !== 'placed' && key !== 'state') find.push(`${key}=${value}`)
      else if (key === 'placed') {
        if (value.start) find.push(`start=${toISO(value.start)}`)
        if (value.end) find.push(`end=${toISO(value.end, 1)}`)
      } else if (key === 'state' && value !== 'ALL') find.push(`${key}=${value}`)
    }
  }

  if (root === 'orders' && !state.filter[root].test) find.push('test=false')
  else if (root === 'orders') find = find.filter(item => item !== 'test=true')

  return find.length === 0 ? '' : `&${find.join('&')}`
}
