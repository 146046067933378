import React from 'react'

export default function Field ({ label, value }) {
  return (
    <p>
      <b>{label}</b>
      <br />
      <span>{value}</span>
    </p>
  )
}
