import qs from 'qs'

const testInParams = (location) => 'test' in qs.parse(location.search, { ignoreQueryPrefix: true })

export default ({ match, location }) => ({
  usedUser: false,
  resourceId: match.params.id,
  isTest: testInParams(location),
  error: false,
  isLoaded: false,
  resource: {
    _links: {}
  },
  form: {
    licenceInformation: {
      agree: false,
      contact: {},
      details: {},
      type: 'COMMERCIAL'
    },
    spatialFilterType: 'CLIPPED'
  }

})
