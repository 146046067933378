import React, { useState } from 'react'

import { Button, Form, InputGroup } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import checkForm from './check-form'
import ErrorMessage from '../../components/error-message'
import SubmitButton from '../../components/submit-button'
import submitResource from '../../fetch/submit-resource'
import SuccessMessage from '../../components/success-message'

const DatasetUrl = ({ state, dispatch, errors }) => (
  <Form.Group>
    <Form.Label>Dataset URL</Form.Label>
    <Form.Control
      onChange={(evt) => dispatch({
        type: 'change',
        payload: {
          datasetUrl: evt.target.value
        }
      })}
      value={state.data.datasetUrl}
    />
    {errors.datasetUrl
      ? <Form.Text className='text-danger'>{errors.datasetUrl}</Form.Text>
      : null}
  </Form.Group>
)

const Workbench = ({ state, dispatch, errors }) => (
  <Form.Group>
    <Form.Label>FME workbench</Form.Label>
    <Form.Control
      as='select'
      value={state.data.workbench}
      onChange={(evt) => dispatch({
        type: 'change',
        payload: {
          workbench: evt.target.value
        }
      })}
    >
      <option />
      {state.workbenches.map(({ caption, value }) => (
        <option key={caption} value={value}>
          {caption}
        </option>
      ))}
    </Form.Control>
    {'workbench' in errors && <Form.Text className='text-danger'>{errors.workbench}</Form.Text>}
  </Form.Group>
)

const Parameters = ({ state, dispatch, errors }) => {
  const [newparamname, setnewparamname] = useState('')
  return (
    <Form.Group>
      <Form.Label>Optionally add preset FME workbench parameters</Form.Label>
      <InputGroup className='mb-2'>
        <Form.Control
          placeholder='Provide a name for a new parameter'
          value={newparamname}
          onChange={(evt) => setnewparamname(evt.target.value)}
        />
        <InputGroup.Append>
          <Button
            variant='success'
            disabled={newparamname === ''}
            onClick={() => {
              dispatch({
                type: 'change',
                payload: {
                  parameters: {
                    [newparamname]: ''
                  }
                }
              })
              setnewparamname('')
            }}
          >Add
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {Object.keys(state.data.parameters).map((paramKey) => {
        return (state.data.parameters[paramKey] !== null) ? (
          <InputGroup className='pl-4 mb-2' key={paramKey}>
            <InputGroup.Prepend>
              <InputGroup.Text>{paramKey}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder='Provide a value for this new parameter'
              value={state.data.parameters[paramKey]}
              onChange={(evt) => dispatch({
                type: 'change',
                payload: {
                  parameters: {
                    [paramKey]: evt.target.value
                  }
                }
              })}
            />
            <InputGroup.Append>
              <Button
                variant='danger'
                value={`${paramKey}`}
                onClick={(evt) => dispatch({
                  type: 'delete-param',
                  payload: {
                    paramKey: evt.target.value
                  }
                })}
              >Delete
              </Button>
            </InputGroup.Append>
          </InputGroup>
        ) : null
      })}
      {'parameters' in errors && <Form.Text className='text-danger'>{errors.parameters}</Form.Text>}
    </Form.Group>
  )
}

const Enabled = ({ state, dispatch }) => (
  <Form.Group>
    <Form.Check
      custom
      id='enabled'
      checked={state.data.enabled}
      type='checkbox'
      label='Enabled?'
      onChange={(evt) => dispatch({
        type: 'change',
        payload: {
          enabled: evt.target.checked
        }
      })}
    />
  </Form.Group>
)

const Buttons = ({ state, dispatch, formErrors, history }) => (
  <Form.Group>
    <Button
      variant='danger'
      className='mr-2'
      onClick={() => {
        history.goBack()
      }}
    >
      <FontAwesomeIcon icon={faTimes} />
      <span className='pl-1'>Cancel</span>
    </Button>
    <SubmitButton
      spin={state.submitted}
      disabled={(state.loading && typeof state.id !== 'undefined') || state.submitted || formErrors.length > 0}
      onClick={() => {
        submitResource(state.data, dispatch, history, typeof state.id !== 'undefined')
      }}
    />
    {state.error && <ErrorMessage>{state.error}</ErrorMessage>}
    {state.message && <SuccessMessage>{state.message}</SuccessMessage>}<br />
  </Form.Group>
)

export default function ResourceForm ({ state, dispatch, history }) {
  let formErrors = checkForm(state.data)
  formErrors = formErrors === true ? [] : formErrors
  const errors = {}

  for (const error of formErrors) errors[error.field] = error.message

  return (
    <>
      {
        state.id
          ? <h4>Edit {state.id}</h4>
          : <h4>Create resource</h4>
      }
      <hr />
      <Form>
        <DatasetUrl
          state={state}
          dispatch={dispatch}
          errors={errors}
        />
        <Workbench
          state={state}
          dispatch={dispatch}
          errors={errors}
        />
        <Parameters
          state={state}
          dispatch={dispatch}
          errors={errors}
        />
        {
          state.id
            ? (
              <Enabled
                state={state}
                dispatch={dispatch}
                errors={errors}
              />
            )
            : null
        }
        <Buttons
          state={state}
          dispatch={dispatch}
          history={history}
          formErrors={formErrors}
        />
      </Form>
    </>
  )
}
