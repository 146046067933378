import React from 'react'
import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'

function Direction ({ direction }) {
  if (direction === 'asc') return <FontAwesomeIcon icon={faSortUp} />
  if (direction === 'desc') return <FontAwesomeIcon icon={faSortDown} />
  return <FontAwesomeIcon icon={faSort} />
}

export default ({ label, onClick, direction }) => (
  <Button
    className='head-link'
    variant='link'
    onClick={(evt) => {
      if (direction) {
        if (direction === 'both') onClick('desc')
        if (direction === 'desc') onClick('asc')
        if (direction === 'asc') onClick('both')
      } else onClick(evt)
    }}
  >
    <span>{label}</span>
    {direction ? (
      <i className='btn-icon'>
        <Direction direction={direction} />
      </i>
    )
      : null}
  </Button>
)
