import React from 'react'

import Form from 'react-bootstrap/Form'
import OrderRadio from './order-radio'
import FormTitle from '../../components/form-title'

export default function AreaOfInterest ({ hasBoundaries, spatialFilterType, onChange }) {
  return (
    <>
      <FormTitle>Define your area of interest</FormTitle>
      <Form.Group>
        <OrderRadio
          checkedName={spatialFilterType}
          onChange={onChange}
          name='CLIPPED'
          label='Draw a boundary on the map'
        />
        {hasBoundaries && (
          <OrderRadio
            checkedName={spatialFilterType}
            onChange={onChange}
            name='PREDEFINED'
            label='Select from a list of boundaries'
          />)}
        <OrderRadio
          checkedName={spatialFilterType}
          onChange={onChange}
          name='WHOLEDATASET'
          label='I want the whole dataset'
        />
      </Form.Group>
    </>
  )
}
