import React from 'react'

import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function SubmitButton (props) {
  const icon = props.icon
  const spin = props.spin
  const buttonProps = Object.assign({}, props)
  delete buttonProps.spin
  delete buttonProps.icon
  return (
    <Button {...buttonProps}>
      <i>
        {spin
          ? <FontAwesomeIcon icon={faSpinner} spin />
          : <FontAwesomeIcon icon={icon || faShare} />}
      </i>
      <span> {props.title || 'Submit'}</span>
    </Button>
  )
}
