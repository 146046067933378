import merge from 'merge'

export default (state, { type, payload }) => {
  const update = {}

  if (type === 'loaded') update.loaded = true
  if (type === 'fetch-error') update.error = payload.error
  if (type === 'resource-response') update.resource = payload.resource

  if (type === 'form-update') {
    update.form = merge.recursive(true, state.form, payload)
  }

  if (type === 'user-ready') {
    update.usedUser = true
    update.form = merge.recursive(true, state.form)

    update.form.licenceInformation.contact.name = `${payload.user.firstName} ${payload.user.lastName}`
    update.form.licenceInformation.contact.email = payload.user.email
  }

  return merge.recursive(true, state, update)
}
