export default async function (url, type, dispatch) {
  const res = await fetch(url)
  if (res.ok) {
    dispatch({
      type: type,
      payload: await res.json()
    })
  } else {
    dispatch({
      type: 'fetch-error',
      payload: `Error: ${res.status}`
    })
  }
}
