import merge from 'merge'

import table from './reducers/table'
import initialState from './table-initial-state'

export default (root) => (state, action) => {
  let update = table(action, root)
  if (action.type === 'reroute') {
    update = initialState(root)
    update.root = action.payload.root
    return update
  }
  return merge.recursive(true, state, update)
}
