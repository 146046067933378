import React, { useState } from 'react'

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

export default function Pagination ({ page, total, perPage, onPageChange }) {
  const [current, setCurrent] = useState(page)
  let maxSize = 38
  if (current >= 10000) maxSize += 40
  else if (current >= 1000) maxSize += 30
  else if (current >= 100) maxSize += 20
  else if (current >= 10) maxSize += 10

  return (
    <div className='pag'>
      <div className='pag-pages'>
        <InputGroup className='pag-number'>
          <FormControl
            style={{ width: maxSize }}
            value={current}
            onChange={(evt) => {
              let newPage = Number(evt.target.value)
              if (!isNaN(newPage)) {
                if (newPage < 1) newPage = 1
                if (newPage > total) newPage = total

                setCurrent(newPage)
                onPageChange(newPage)
              }
            }}
          />
          <InputGroup.Append>
            <InputGroup.Text>/ {total}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <ButtonGroup>
          <Button
            onClick={() => {
              setCurrent(current - 1)
              onPageChange(current - 1)
            }}
            disabled={current === 1}
          >
            <i className='btn-icon'>
              <FontAwesomeIcon icon={faAngleLeft} />
            </i>
          </Button>
          <Button
            onClick={() => {
              setCurrent(current + 1)
              onPageChange(current + 1)
            }}
            disabled={current === total}
          >
            <i className='btn-icon'>
              <FontAwesomeIcon icon={faAngleRight} />
            </i>
          </Button>
        </ButtonGroup>
      </div>
      {perPage && <p className='pag-per-page'>{perPage}</p>}
    </div>
  )
}
