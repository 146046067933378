import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { InputGroup } from 'react-bootstrap'
import Head from './head'
import HeadLink from './head-link'

export default ({ label, onChange, start, end, direction, onDirectionChange }) => (
  <Head className='head-date-range'>
    <HeadLink
      label={label}
      direction={direction}
      onClick={onDirectionChange}
    />
    <InputGroup>
      <DatePicker
        selected={start}
        dateFormat='yyyy-MM-dd'
        placeholderText='Start Date'
        onChange={(date) => {
          onChange({
            start: date,
            end
          })
        }}
      />
      <DatePicker
        selected={end}
        dateFormat='yyyy-MM-dd'
        placeholderText='End Date'
        onChange={(date) => {
          onChange({
            end: date,
            start
          })
        }}
      />

    </InputGroup>
  </Head>
)
