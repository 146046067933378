import React, { useReducer, useEffect } from 'react'
import { Container, Spinner } from 'react-bootstrap'

import ResourceForm from './resource-form'
import initialState from './initial-state.js'
import reducer from './resource-reducer'

const getResource = async (id, dispatch) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/resources/${id}`)
    const resource = await res.json()
    dispatch({
      type: 'fetch-resource',
      payload: {
        resource
      }
    })
  } catch (err) {
    console.log(err)
  }
}

const Loading = () => (
  <div className='loading'>
    <Spinner animation='border' role='status'>
      <span className='sr-only'>Loading...</span>
    </Spinner>
    <span className='loading-label'>Loading...</span>
  </div>
)

export default ({ history, match }) => {
  const id = match.params.id
  const [state, dispatch] = useReducer(reducer, initialState(id))

  useEffect(() => {
    if (state.loading && typeof id !== 'undefined') {
      getResource(id, dispatch)
    }

    if (state.id !== id) {
      dispatch({
        type: 're-routed',
        payload: {
          id
        }
      })
    }
  }, [id, state])

  return (
    <Container className='resource'>
      {state.loading && typeof id !== 'undefined' ? <Loading /> : null}
      <ResourceForm
        state={state}
        dispatch={dispatch}
        history={history}
      />
    </Container>
  )
}
