import React, { useState } from 'react'

import 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'

import { Map, TileLayer, FeatureGroup, LayerGroup, Rectangle } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import getWKT from './get-well-known-text'
import { EPSG4326ToEPSG3857 } from './reproject-wkt'

const ATTRIBUTION = '&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
const DEFAULT_BBOX = [[49.864, -8.648], [60.861, 1.768]]
const DRAW_PROPS = {
  polyline: false,
  marker: false,
  circlemarker: false,
  circle: false
}
const RECT_PROPS = {
  color: 'darkgreen',
  fillOpacity: 0.1
}
const TILE_SERVER = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

function OrderMapDraw ({ onChange }) {
  return (
    <FeatureGroup>
      <EditControl
        onEdited={onChange}
        onCreated={onChange}
        onDeleted={onChange}
        draw={DRAW_PROPS}
      />
    </FeatureGroup>
  )
}

function calculateBounds (boundingBoxes) {
  return boundingBoxes.reduce(
    (acc, curr) => {
      acc[0][0] = (curr.southBoundLatitude < acc[0][0]) ? curr.southBoundLatitude : acc[0][0]
      acc[0][1] = (curr.westBoundLongitude < acc[0][1]) ? curr.westBoundLongitude : acc[0][1]
      acc[1][0] = (curr.northBoundLatitude > acc[1][0]) ? curr.northBoundLatitude : acc[1][0]
      acc[1][1] = (curr.eastBoundLongitude > acc[1][1]) ? curr.eastBoundLongitude : acc[1][1]
      return acc
    },
    [[500, 500], [-500, -500]])
}

function calculateRectangles (boundingBoxes) {
  return boundingBoxes.reduce((acc, bbox, index) => {
    acc.push((
      <Rectangle
        key={index}
        bounds={[
          [bbox.southBoundLatitude, bbox.westBoundLongitude],
          [bbox.northBoundLatitude, bbox.eastBoundLongitude]
        ]}
        {...RECT_PROPS}
      />
    ))
    return acc
  }, [])
}

export default function OrderMap ({ boundingBoxes, draw, onChange }) {
  const [tmpLayer, setTmpLayer] = useState(null)
  const bounds = boundingBoxes ? calculateBounds(boundingBoxes) : DEFAULT_BBOX
  const rectangles = boundingBoxes ? calculateRectangles(boundingBoxes) : []

  function onDrawChange ({ sourceTarget, layer, type }) {
    if (type === 'draw:created' && tmpLayer !== null) sourceTarget.removeLayer(tmpLayer)
    if (type === 'draw:deleted') layer = null
    if (type === 'draw:edited') layer = tmpLayer

    setTmpLayer(layer)
    if (layer !== null) onChange(EPSG4326ToEPSG3857(getWKT(layer.toGeoJSON())))
    else onChange(getWKT(layer))
  }

  return (
    <div className='map'>
      <Map bounds={bounds}>
        <LayerGroup>
          {rectangles}
        </LayerGroup>
        <TileLayer url={TILE_SERVER} attribution={ATTRIBUTION} />
        {
          // eslint-disable-next-line react/jsx-no-bind
        }{draw && <OrderMapDraw onChange={onDrawChange} />}
      </Map>
    </div>
  )
}
