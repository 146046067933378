import React from 'react'

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

export default function Select ({ options, value, onChange }) {
  return (
    <Form.Group>
      <InputGroup>
        <FormControl
          as='select'
          value={value}
          onChange={(evt) => { onChange(evt.target.value) }}
        >
          <option />
          {options.map(({ caption, value }) => (
            <option key={caption} value={value}>
              {caption}
            </option>
          ))}
        </FormControl>
      </InputGroup>
    </Form.Group>
  )
}

Select.defaultOptions = {
  options: []
}
