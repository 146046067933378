import fetchError from './fetch-error'
import fetchResponse from './fetch-response'
import pageChange from './page-change'
import filter from './filter'

export default ({ type, payload }, root) => {
  let update = {}
  if (type === 'fetch-response') update = fetchResponse(payload, root)
  if (type === 'page-change') update = pageChange(payload)
  if (type === 'filter') update = filter(payload, root)
  update.error = false
  if (type === 'fetch-error') {
    update = fetchError
    update.rows = []
  }
  return update
}
