import React, { useState } from 'react'

import Submit from '../../../components/submit-button'
import { Col, Row, Form, InputGroup, FormControl } from 'react-bootstrap'

import { faCheck, faRedoAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

const acceptRelation = 'om:accept'
const restartRelation = 'om:restart'
const rejectRelation = 'om:reject'
const completeRelation = 'om:complete'

const showActions = (order) => hasLink(order, restartRelation) ||
  hasLink(order, acceptRelation) ||
  hasLink(order, rejectRelation) ||
  hasLink(order, completeRelation)

const hasLink = (order, relation) => order._links && relation in order._links

const link = (order, relation) => order._links[relation].href

const Title = () => (
  <Row>
    <h4>Actions</h4>
  </Row>
)

const Restart = ({ order, onClick }) => hasLink(order, restartRelation)
  ? <Submit title='Restart' className='mr-2' onClick={onClick} icon={faRedoAlt} />
  : null

const Complete = ({ order, onClick }) => hasLink(order, completeRelation)
  ? <Submit title='Complete' onClick={onClick} icon={faCheck} variant='success' />
  : null

const Reject = ({ order, reason, onChange, onClick }) => hasLink(order, rejectRelation)
  ? (
    <Form>
      <Form.Group>
        <Form.Label>Reason for Rejection</Form.Label>
        <Form.Control as='textarea' rows='3' value={reason} onChange={onChange} />
        <div className='mb-1'>
          {reason === '' ? <Form.Text className='text-danger'>Reason for Rejection is Required</Form.Text> : null}
        </div>
        <Submit
          icon={faTimes}
          variant='danger'
          title='Reject'
          disabled={reason === ''}
          className='mt-2'
          onClick={onClick}
        />
      </Form.Group>
    </Form>
  )
  : null

const Accept = ({ order, licenceNumber, onChange, onClick }) => hasLink(order, acceptRelation)
  ? (
    <Form>
      <Form.Group>
        <Form.Label>Licence Number</Form.Label>
        <InputGroup>
          <FormControl value={licenceNumber} onChange={onChange} />
          <InputGroup.Append>
            <Submit
              icon={faCheck}
              variant='success'
              title='Approve'
              disabled={licenceNumber === ''}
              onClick={onClick}
            />
          </InputGroup.Append>
        </InputGroup>
        {licenceNumber === '' ? <Form.Text className='text-danger'>Licence Number is Required</Form.Text> : null}
      </Form.Group>
    </Form>
  )
  : null

const ActionRow = ({ children }) => (
  <Row className='mb-4'>
    <Col xs={12}>
      {children}
    </Col>
  </Row>
)

export default ({ order, onAction }) => {
  const [state, setState] = useState({ reason: '', licenceNumber: '' })
  return showActions(order)
    ? (
      <>
        <Title />
        <ActionRow>
          <Restart
            onClick={() => {
              onAction(link(order, restartRelation))
            }}
            order={order}
          />
          <Complete
            onClick={() => {
              onAction(link(order, completeRelation))
            }}
            order={order}
          />
        </ActionRow>
        <ActionRow>
          <Reject
            order={order}
            reason={state.reason}
            onChange={(evt) => {
              setState({
                licenceNumber: state.licenceNumber,
                reason: evt.target.value
              })
            }}
            onClick={() => {
              onAction(link(order, rejectRelation), state.reason)
            }}
          />
        </ActionRow>
        <ActionRow>
          <Accept
            order={order}
            licenceNumber={state.licenceNumber}
            onChange={(evt) => {
              setState({
                reason: state.reason,
                licenceNumber: evt.target.value
              })
            }}
            onClick={() => {
              onAction(link(order, acceptRelation), state.licenceNumber)
            }}
          />
        </ActionRow>
      </>
    )
    : null
}
