import Validator from 'fastest-validator'

const validator = new Validator()

export default validator.compile({
  workbench: {
    type: 'string',
    empty: false,
    pattern: /(.*)\.(.*)$/i,
    messages: {
      required: 'FME Workbench is required',
      stringEmpty: 'FME Workbench is required',
      stringPattern: 'Does not have the extension e.g. name.fmw'
    }
  },
  datasetUrl: {
    type: 'url',
    messages: {
      required: 'Dataset URL is required',
      url: 'Dataset URL must be a valid URL'
    }
  }
})
