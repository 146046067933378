const ordersFilter = {
  test: false,
  id: '',
  shortId: '',
  resourceTitle: '',
  placed: {
    start: '',
    end: ''
  },
  username: '',
  state: 'ALL'
}

const ordersSort = {
  id: 'both',
  shortId: 'both',
  resourceTitle: 'both',
  placed: 'desc',
  username: 'both',
  state: 'both'
}

const resourcesFilter = {
  id: '',
  title: '',
  datasetUrl: '',
  licence: '',
  enabled: false
}

const resourcesSort = {
  id: 'desc',
  title: 'both',
  datasetUrl: 'both',
  licence: 'both'
}

export default (root) => ({
  loading: true,
  error: false,
  rows: [],
  page: {
    number: 1,
    size: 20,
    total: 1
  },
  filter: {
    orders: ordersFilter,
    resources: resourcesFilter
  },
  sort: {
    orders: ordersSort,
    resources: resourcesSort
  }
})
