import React from 'react'
import HeadCheckbox from '../../components/table/head-checkbox'
import HeadInput from '../../components/table/head-input'

export default ({ state, onChange }) => (
  <tr>
    <HeadCheckbox
      label='Enabled'
      checked={state.filter.resources.enabled}
      onChange={(evt) => {
        onChange({
          field: 'enabled',
          value: evt.target.checked
        })
      }}
    />
    <HeadInput
      className='resource-uid'
      label='UID'
      direction={state.sort.resources.id}
      value={state.filter.resources.id}
      onChange={(evt) => {
        onChange({
          field: 'id',
          value: evt.target.value
        })
      }}
      onDirectionChange={(sort) => {
        onChange({
          field: 'id',
          sort
        })
      }}
    />
    <HeadInput
      label='Title'
      direction={state.sort.resources.title}
      value={state.filter.resources.title}
      onChange={(evt) => {
        onChange({
          field: 'title',
          value: evt.target.value
        })
      }}
      onDirectionChange={(sort) => {
        onChange({
          field: 'title',
          sort
        })
      }}
    />
    <HeadInput
      label='Dataset URL'
      direction={state.sort.resources.datasetUrl}
      value={state.filter.resources.datasetUrl}
      onChange={(evt) => {
        onChange({
          field: 'datasetUrl',
          value: evt.target.value
        })
      }}
      onDirectionChange={(sort) => {
        onChange({
          field: 'datasetUrl',
          sort
        })
      }}
    />
    <HeadInput
      label='Licence'
      direction={state.sort.resources.licence}
      value={state.filter.resources.licence}
      onChange={(evt) => {
        onChange({
          field: 'licence',
          value: evt.target.value
        })
      }}
      onDirectionChange={(sort) => {
        onChange({
          field: 'licence',
          sort
        })
      }}
    />

  </tr>
)
