export default (payload, root) => {
  const update = {}
  update.loading = true
  if ('value' in payload.change) {
    update.filter = {}
    update.filter[root] = {}
    update.filter[root][payload.change.field] = payload.change.value
  } else if ('sort' in payload.change) {
    update.sort = {}
    update.sort[root] = {}
    update.sort[root][payload.change.field] = payload.change.sort
  }
  return update
}
