import React from 'react'

import FormSubtitle from '../../components/form-subtitle'
import FormTitle from '../../components/form-title'
import Input from '../../components/input'
import LicenceType from './licence-type'
import Radio from '../../components/radio'
import { Form } from 'react-bootstrap'

const ACADEMIC = 'ACADEMIC'

function AcademicDetails ({ details, errors, onChange }) {
  return (
    <>
      <div className='note'><b>Please note</b>: for academic/educational use, individuals may be able to access UKCEH’s Land Cover Maps, Land Cover<sup>&reg;</sup> plus: Crops, and Land Cover<sup>&reg;</sup> plus Fertilisers & Pesticides datasets free of charge from <a href='https://digimap.edina.ac.uk/digimap/home'>EDINA</a> (depending on access negotiated by your academic institution).</div>
      <Input
        value={details.projectName}
        onChange={(event) => onChange('details', 'projectName', event.target.value)}
        field='projectName'
        label='Project Name'
        error={errors.details.projectName}
      />
      <Input
        value={details.duration}
        onChange={(event) => onChange('details', 'duration', Number(event.target.value))}
        field='duration'
        label='Expected project duration (years)'
        error={errors.details.duration}
      />
      <Input
        value={details.fundingSource}
        onChange={(event) => onChange('details', 'fundingSource', event.target.value)}
        field='fundingSource'
        label='Funding source'
        error={errors.details.fundingSource}
      />
      <Radio
        className='licence-is-student'
        type='checkbox'
        onChange={(event) => onChange('details', 'student', event.target.checked)}
        name='student'
        label='This data to be used by a student'
      />
    </>
  )
}

const LicenceAgreement = ({ licenceInformation, errors, onChange }) => (
  <div className='licence-form-agreement mt-3'>
    <Form.Check
      className='mb-1'
      checked={licenceInformation.agree}
      id='agree'
      onChange={(evt) => {
        onChange(Object.assign({}, licenceInformation, { agree: evt.target.checked }))
      }}
      type='checkbox'
      custom
      label='I understand that my personal information will be retained in accordance with the UKCEH Privacy Policy for the purposes of data supply and licensing'
    />
    <p className='mb-1'>Please see the <a href='https://www.ceh.ac.uk/privacy-notice' target='_blank' rel='noopener noreferrer'>UKCEH Privacy Policy</a> for more information</p>
    <Form.Text className='text-danger'>{errors.agree}</Form.Text>
  </div>
)

// name and email, should come from Crowd by default
export default function LicenceInfoForm ({ licenceInformation, errors, onChange }) {
  function update (object, field, value) {
    const updated = Object.assign({}, licenceInformation[object])
    updated[field] = value
    onChange(Object.assign({}, licenceInformation, { [object]: updated }))
  }

  function updateType (type) {
    onChange(Object.assign({}, licenceInformation, { type }))
  }

  return (
    <div className='form-section'>
      <FormTitle>Information required for data licence</FormTitle>
      <Input
        value={licenceInformation.contact.name}
        onChange={(event) => update('contact', 'name', event.target.value)}
        field='name'
        label='Name'
        error={errors.contact.name}
      />
      <Input
        value={licenceInformation.contact.email}
        onChange={(event) => update('contact', 'email', event.target.value)}
        field='email'
        label='Email'
        error={errors.contact.email}
      />
      <Input
        value={licenceInformation.contact.organisation}
        onChange={(event) => update('contact', 'organisation', event.target.value)}
        field='organisation'
        label='Organisation'
        error={errors.contact.organisation}
      />
      <Input
        value={licenceInformation.contact.telephone}
        onChange={(event) => update('contact', 'telephone', event.target.value)}
        field='telephone'
        label='Telephone'
        error={errors.contact.telephone}
      />
      <Input
        value={licenceInformation.contact.other}
        onChange={(event) => update('contact', 'other', event.target.value)}
        field='other'
        label='Address'
        type='textarea'
        error={errors.contact.other}
      />
      <FormSubtitle>Use type</FormSubtitle>
      <div className='usetype'>
        {
          // eslint-disable-next-line react/jsx-no-bind
        }<LicenceType licenceType={licenceInformation.type} onChange={updateType} />
      </div>
      {
        // eslint-disable-next-line react/jsx-no-bind
      }{licenceInformation.type === ACADEMIC && <AcademicDetails details={licenceInformation.details} errors={errors} onChange={update} />}
      <Input
        value={licenceInformation.details.intendedUse}
        onChange={(event) => update('details', 'intendedUse', event.target.value)}
        field='intendedUse'
        label='Description of intended use'
        type='textarea'
        error={errors.details.intendedUse}
      />
      <LicenceAgreement licenceInformation={licenceInformation} errors={errors} onChange={onChange} />
    </div>
  )
}
