import React, { useContext } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Navbar from './components/navbar'
import OrderCreatePage from './pages/order/order-create-page'
import OrderDetailPage from './pages/order/order-detail-page'
import ResourceCreatePage from './pages/resource/resource-create-page'
import ResourceDetailPage from './pages/resource/resources-detail-page'
import TablePage from './pages/table/table-page'
import UserContext from './components/user-context'

export default () => {
  const user = useContext(UserContext)
  const currentLocation = encodeURIComponent(window.location.href)
  return (
    <>
      {
        !user.init ? (
          <Router>
            <header>
              <Navbar />
            </header>
            <main className='main'>
              <Switch>
                {!user.loggedIn &&
                  <Route
                    path='/'
                    component={() => {
                      window.location.href = `/sso/login?success=${currentLocation}`
                      return null
                    }}
                  />}
                <Redirect exact path='/resources/edit' to='/resources/new' />
                <Route exact path='/resources/new' component={ResourceCreatePage} />
                <Route exact path='/resources/:id/order' component={OrderCreatePage} />
                <Route exact path='/resources/edit/:id' component={ResourceCreatePage} />
                <Route exact path='/resources/:id/:revision?' component={ResourceDetailPage} />
                <Route exact path='/orders/:id' component={OrderDetailPage} />
                <Route exact path='/:root(orders|resources)' component={TablePage} />
              </Switch>
            </main>
          </Router>
        ) : null
      }
    </>
  )
}
