import React from 'react'

import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default function ErrorMessage ({ children }) {
  return (
    <Form.Text className='text-danger resource-form-message'>
      <i className='resource-form-message-icon'>
        <FontAwesomeIcon icon={faTimes} />
      </i>
      {children}
    </Form.Text>
  )
}
