import React, { createRef } from 'react'
import { Form } from 'react-bootstrap'
import Head from './head'
import HeadLink from './head-link'

export default ({ label, checked, onChange }) => {
  const checkboxRef = createRef()
  return (
    <Head className='head-checkbox'>
      <HeadLink
        label={label}
        onClick={() => {
          checkboxRef.current.click()
        }}
      />
      <div className='head-checkbox-check'>
        <Form.Check
          ref={checkboxRef}
          checked={checked}
          onChange={onChange}
          custom
          id={label}
          type='checkbox'
          label=''
        />
      </div>

    </Head>
  )
}
