function extractErrorMessages (message) {
  return ('errors' in message)
    ? message.errors.join(', ')
    : 'Error'
}

export async function get (url, setError, setIsLoaded, set) {
  try {
    const response = await fetch(url)
    if (response.ok) {
      set(await response.json())
      setIsLoaded(true)
    } else {
      setError(extractErrorMessages(await response.json()))
    }
  } catch {
    setError('Error')
  }
  setIsLoaded(true)
}

export async function post (history, url, body, forwardTo, setError) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: body
    })
    if (response.ok) {
      const fromServer = await response.json()
      history.push(`${forwardTo}/${fromServer.id}`)
    } else {
      setError(extractErrorMessages(await response.json()))
    }
  } catch {
    setError('Error')
  }
}
