import React from 'react'

export default function Field ({ label, value, href }) {
  return (
    <p>
      <b>{label}</b>
      <br />
      <a href={href} target='_blank' rel='noopener noreferrer'>{value}</a>
    </p>
  )
}
