import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import UserContext from '../user-context'
import logout from './logout'

export default () => {
  const history = useHistory()
  const user = useContext(UserContext)
  const showButtons = user.roles.includes('ordermanager-admin')

  return (
    <Navbar bg='dark' variant='dark' expand='lg'>
      <Container>
        <Navbar.Brand>
          <Image src='/brand.png' />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav.Link href='http://eidc.ceh.ac.uk'>EIDC</Nav.Link>
          <Nav.Link href='https://catalogue.ceh.ac.uk/eidc/documents'>Find data</Nav.Link>
          <Nav.Link href='http://eidc.ceh.ac.uk/deposit'>Deposit Data</Nav.Link>
          <Nav.Link href='http://eidc.ceh.ac.uk/support'>Support</Nav.Link>
          <Nav.Link href='http://eidc.ceh.ac.uk/about'>About</Nav.Link>
          <Nav.Link href='http://eidc.ceh.ac.uk/contact'>Contact us</Nav.Link>
          <Nav.Link href='http://eidc.ceh.ac.uk/help'>Help</Nav.Link>
        </Navbar.Collapse>
        <Navbar.Collapse className='justify-content-end'>
          {user.loggedIn
            ? (
              <NavDropdown title={<FontAwesomeIcon icon={faUser} />}>
                <NavDropdown.Item onClick={() => history.push('/orders')}>Orders</NavDropdown.Item>
                {
                  showButtons
                    ? (
                      <>
                        <NavDropdown.Item onClick={() => history.push('/resources')}>Resources</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push('/resources/new')}>Create Resource</NavDropdown.Item>

                      </>
                    )
                    : null
                }
                <NavDropdown.Divider />
                <NavDropdown.Item href='https://catalogue.ceh.ac.uk/sso/profile'>Profile</NavDropdown.Item>
                <NavDropdown.Item href='https://catalogue.ceh.ac.uk/sso/password/change'>Change Password</NavDropdown.Item>
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            )
            : <Nav.Link href={`/sso/login?success=${window.location.href}`}>Login</Nav.Link>}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
