import React from 'react'

import { Button, Row } from 'react-bootstrap'
import Field from '../../components/field'
import FieldLink from '../../components/field-link'
import Licence from './licence'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

function BoundingBoxes ({ value }) {
  value = value || []
  return (
    <p>
      <b>Bounding box(es) (EPSG:4326)</b>
      <br />
      {value.map((bbox, index) => {
        return (
          <React.Fragment key={index}>
            <span className='inline-field-name'>north: </span><span>{bbox.northBoundLatitude} </span>
            <span className='inline-field-name'>south: </span><span>{bbox.southBoundLatitude} </span>
            <span className='inline-field-name'>east: </span><span>{bbox.eastBoundLongitude} </span>
            <span className='inline-field-name'>west: </span><span>{bbox.westBoundLongitude}</span>
            <br />
          </React.Fragment>
        )
      })}
    </p>
  )
}

function Parameters ({ parameters }) {
  return (
    (parameters && Object.keys(parameters).length > 0) ? (
      <Row>
        <p>
          <b>FME workbench parameters</b>
          <br />
          {Object.keys(parameters).map((paramKey, index) => {
            return (
              <React.Fragment key={index}>
                <span className='inline-field-name'>{paramKey}: </span><span>{parameters[paramKey]} </span>
                <br />
              </React.Fragment>
            )
          })}
        </p>
      </Row>
    ) : null
  )
}

const ResourceDisabled = () => (
  <div>
    <p className='text-danger'>
      <FontAwesomeIcon icon={faExclamationTriangle} /> This resource is disabled
    </p>
  </div>
)

const OldRevision = ({ resource, history }) => (
  <div>
    <p className='text-danger'>
      <FontAwesomeIcon icon={faExclamationTriangle} /> This is an old revsion
    </p>
  </div>
)

const LatestRevisionButtons = ({ history, resource }) => (
  <>
    <Button
      disabled={!resource.enabled}
      onClick={() => {
        history.push(`/resources/${resource.id}/order?test=true`)
      }}
    >
      Submit Test Order
    </Button>
    {
      'om:save' in resource._links
        ? (
          <Button
            className='ml-2'
            onClick={() => {
              history.push(`/resources/edit/${resource.id}`)
            }}
          >
            Edit
          </Button>
        )
        : null
    }
  </>
)

const RevisionButtons = ({ history, resource }) => (
  <Button
    onClick={() => {
      history.push(`/resources/${resource.id}`)
    }}
  >
    Go to Latest
  </Button>
)

const Data = ({ resource }) => (
  <>
    <Row>
      <Field label='Resource id' value={resource.id} />
    </Row>
    <Row>
      <Field label='Dataset title' value={resource.title} />
    </Row>
    <Row>
      <FieldLink label='Dataset URL' value={resource.datasetUrl} href={resource.datasetUrl} />
    </Row>
    <Row>
      <BoundingBoxes value={resource.boundingBoxes} />
    </Row>
    <Row>
      <Field label='FME repository' value={resource.repository} />
    </Row>
    <Row>
      <Field label='FME workbench' value={resource.workbench} />
    </Row>
    <Parameters parameters={resource.parameters} />
    <Row>
      <Licence licence={resource.licence} />
    </Row>
  </>
)

export default function ResourceData ({ history, resource, revision }) {
  const useRevision = typeof revision !== 'undefined'
  if (useRevision) resource = resource.revisions[revision]
  return (
    <div>
      <Row>
        <h4> Resource details</h4>
        <hr />
      </Row>
      <Data resource={resource} />
      <Row>
        {
          !useRevision
            ? <LatestRevisionButtons resource={resource} history={history} />
            : <RevisionButtons resource={resource} history={history} />
        }
      </Row>
      <Row className='mt-2'>
        {!useRevision ? null : <OldRevision resource={resource} history={history} />}
        {!useRevision && !resource.enabled ? <ResourceDisabled resource={resource} /> : null}
      </Row>
    </div>
  )
}
