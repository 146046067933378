import React from 'react'

import Form from 'react-bootstrap/Form'
import OrderRadio from './order-radio'

export default function LicenceType ({ licenceType, onChange }) {
  return (
    <Form.Group>
      <OrderRadio
        checkedName={licenceType}
        onChange={onChange}
        name='COMMERCIAL'
        label='Internal business use'
        labelInfo='Use in an organisation, including consultancy.  This use does not involve supplying data, which either comprises a substantial part of the dataset or was developed using the dataset, to any third party'
      />
      <OrderRadio
        checkedName={licenceType}
        onChange={onChange}
        name='INNOVATION'
        label='Innovation'
        labelInfo='The development of products or services created from Licensed Data with the intention of subsequently entering into a value-added reselling agreement.'
      />
      <OrderRadio
        checkedName={licenceType}
        onChange={onChange}
        name='PERSONAL'
        label='Personal use'
        labelInfo='Use which is not Academic Research and not Internal Business use , is restricted to an individual acting in a private capacity and does not involve commercial gain.'
      />
      <OrderRadio
        checkedName={licenceType}
        onChange={onChange}
        name='ACADEMIC'
        label='Academic research'
        labelInfo='Research to further a body of knowledge. Any resulting research will be disseminated widely and without delay and will not be held for the exclusive information or use of the research sponsor'
      />
      <OrderRadio
        checkedName={licenceType}
        onChange={onChange}
        name='EDUCATIONAL'
        label='Educational'
        labelInfo='Use for the purpose of teaching, learning and/or teacher training and which does not involve direct or indirect commercial gain.'
      />
    </Form.Group>
  )
}
