import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormTitle from '../../components/form-title'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

export default function LicenceAgreement ({ licence }) {
  return (
    <Row className='order-data-licence'>
      <Col xs={12}>
        <FormTitle>Licence Agreement</FormTitle>
      </Col>
      <Col xs={12}>
        <a
          href={licence.url}
          rel='noopener noreferrer'
          target='_blank'
        >
          <i className='order-data-licence-agreement-link-icon'>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </i>
          <span className='order-data-licence-agreement-link-label'>{licence.name}</span>
        </a>
        <p className='order-data-licence-agreement-text'>
          <i className='order-data-licence-agreement-text-icon'>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </i>
          <span>By submitting this order you agree to the Terms and Conditions outlined in the licence</span>
        </p>
      </Col>
    </Row>
  )
}
