import React from 'react'

import Form from 'react-bootstrap/Form'

export default function Input ({ value, onChange, errors = {}, field, label, type = 'input', error }) {
  return (
    <Form.Group controlId={field}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as={type}
        onChange={onChange}
        value={value || ''}
      />
      {error || field in errors
        ? <Form.Text className='text-danger'>{error || errors[field]}</Form.Text>
        : null}
    </Form.Group>
  )
}
