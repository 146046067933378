import React from 'react'

export default function OrderHead ({ test, resourceTitle }) {
  if (resourceTitle) {
    return (
      <div className={`order-head is-${test ? 'test' : 'not-test'}`}>
        <h3>
          <small>TEST</small>
          <span>{resourceTitle}</span>
        </h3>
      </div>
    )
  } else {
    return null
  }
}
