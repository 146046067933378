import React from 'react'

// BoostratReact Form.Check does not work as a radio should
export default function Radio (props) {
  let { name, label, type, labelInfo } = props
  type = type || 'radio'
  return (
    <div className={`custom-control custom-${type} ${props.className || ''}`}>
      <input
        {...props}
        id={name}
        type={type}
        className='custom-control-input'
      />
      <label className='custom-control-label' htmlFor={name}>
        {label}
        {labelInfo && (<span className='label-info'><br />{labelInfo}</span>)}
      </label>
    </div>
  )
}
