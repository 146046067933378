import React from 'react'
import { InputGroup, FormControl } from 'react-bootstrap'
import Head from './head'
import HeadLink from './head-link'

export default ({ className, values, value, label, onChange, direction, onDirectionChange }) => (
  <Head className={`${className} head-select`}>
    <HeadLink
      label={label}
      direction={direction}
      onClick={onDirectionChange}
    />
    <InputGroup>
      <FormControl
        value={value}
        as='select'
        onChange={onChange}
      >
        {values.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
      </FormControl>
    </InputGroup>
  </Head>
)
