import React from 'react'

import Form from 'react-bootstrap/Form'
import Select from '../../components/select'

export default function Boundary ({ isPredefined, boundary, options, onChange }) {
  return isPredefined && (
    <Form.Group>
      <Select
        options={options}
        value={boundary}
        onChange={onChange}
      />
    </Form.Group>
  )
}
