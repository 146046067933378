import Validator from 'fastest-validator'

const validator = new Validator()

const checkContact = validator.compile({
  name: {
    type: 'string',
    empty: false,
    messages: {
      required: 'Name is required',
      stringEmpty: 'Name is required'
    }
  },
  email: {
    type: 'email',
    messages: {
      required: 'Email is required',
      email: 'Not a valid email'
    }
  },
  organisation: {
    type: 'string',
    empty: false,
    messages: {
      required: 'Organisation is required',
      stringEmpty: 'Organisation is required'
    }
  },
  telephone: {
    type: 'string',
    optional: true
  },
  other: {
    type: 'string',
    optional: true
  }
})

const checkNonAcademicDetails = validator.compile({
  intendedUse: {
    type: 'string',
    empty: false,
    messages: {
      required: 'Intended use is required',
      stringEmpty: 'Intended use is required'
    }
  }
})

const checkAcademicDetails = validator.compile({
  projectName: {
    type: 'string',
    empty: false,
    messages: {
      required: 'Project name is required',
      stringEmpty: 'Project name is required'
    }
  },
  duration: {
    type: 'number',
    positive: true,
    integer: true,
    messages: {
      required: 'Duration is required',
      number: 'Duration must be a number',
      numberInteger: 'Duration must be in years',
      numberPositive: 'Duration must be in years greater than 0'
    }
  },
  fundingSource: {
    type: 'string',
    empty: false,
    messages: {
      required: 'Funding source is required',
      stringEmpty: 'Funding source is required'
    }
  }
})

export default (form) => {
  const errors = {
    licenceInformation: {
      contact: {},
      details: {}
    }
  }
  const contactErrors = checkContact(form.licenceInformation.contact)
  if (contactErrors !== true) {
    for (const { field, message } of contactErrors) {
      errors.licenceInformation.contact[field] = message
    }
  }

  const nonAcademicErrors = checkNonAcademicDetails(form.licenceInformation.details)
  if (nonAcademicErrors !== true) {
    for (const { field, message } of nonAcademicErrors) {
      errors.licenceInformation.details[field] = message
    }
  }

  const academicErrors = checkAcademicDetails(form.licenceInformation.details)
  if (academicErrors !== true) {
    for (const { field, message } of academicErrors) {
      errors.licenceInformation.details[field] = message
    }
  }

  if (!form.licenceInformation.agree) errors.licenceInformation.agree = 'Please check the box to confirm that you understand'

  if (form.spatialFilterType === 'CLIPPED' && (form.wkt === undefined || form.wkt === '')) {
    errors.wkt = 'Boundary is required'
  }

  return errors
}
