import React, { useContext } from 'react'

import { EPSG3857ToEPSG4326 } from './reproject-wkt'

import Col from 'react-bootstrap/Col'
import Field from '../../components/field'
import Head from '../../components/table/head'
import OrderActions from './order-actions'
import OrderLicence from './order-licence'
import PreviewMap from '../../components/preview-map'
import Row from 'react-bootstrap/Row'
import Table from '../../components/table/table'
import UserContext from '../../components/user-context'

const OrderMessages = ({ order }) => 'messages' in order
  ? (
    <div className='mt-5'>
      <Row>
        <h4>Messages</h4>
      </Row>
      <Row className='mb-4'>
        <Col xs={12}>
          <Table
            head={

              <tr>
                <Head className='order-message-time'>Time</Head>
                <Head>Message</Head>
              </tr>
            }
            body={
              order.messages.map(({ timestamp, content }, index) => (
                <tr key={`message-${index}`}>
                  <td><b>{new Date(timestamp).toISOString().replace(/\..*$/g, '').replace('T', ' ')}</b></td>
                  <td>{content}</td>
                </tr>
              ))
            }
          />
        </Col>
      </Row>
    </div>
  )
  : null

const OrderInfo = ({ order, cols, isAdmin }) => (
  <Col md={cols}>
    <Field label='State' value={order.state} />
    {order.rejectionReason && <Field label='Rejection Reason' value={order.rejectionReason} />}
    <Field label='Order placed' value={new Date(order.placed).toLocaleString()} />
    <Field label='Reference' value={order.id} />
    {order.test && <Field label='Test' value='Yes' />}
    {order.licenceNumber && <Field label='Licence Number' value={order.licenceNumber} />}
    {
      (hasPredefined(order) && !isAdmin) && (
        <Field
          label='Predefined region'
          value={order.parameters.Clipper}
        />
      )
    }
  </Col>
)

const OrderParameters = ({ order, cols }) =>
  <Col md={cols}>
    {Object.keys(order.parameters).length === 0 &&
      <span className='order-no-parameters'>THIS ORDER HAS NO PARAMETERS</span>}
    {Object.keys(order.parameters).map(key => {
      return (
        <Field
          key={key}
          label={key}
          value={order.parameters[key]}
        />)
    })}
    {order.totalArea &&
      <Field
        label='Total area (km&#178;)'
        value={order.totalArea}
      />}
    <Field label='User' value={order.username} />
  </Col>

const hasParameters = (order) => 'parameters' in order
const hasPolygon = (order) => hasParameters(order) && 'WellKnownTextInEPSG3857' in order.parameters
const hasPredefined = (order) => hasParameters(order) && 'Clipper' in order.parameters

export default function OrderData ({ order, onAction }) {
  const cols = hasPolygon(order) ? 4 : 6
  const user = useContext(UserContext)
  return (
    <>
      <Row>
        <h4>Order details</h4>
      </Row>
      <Row>
        <OrderInfo order={order} cols={cols} isAdmin={user.isAdminOrLicensing} />
        {
          hasPolygon(order) && (
            <PreviewMap
              cols={cols}
              wkt={EPSG3857ToEPSG4326(order.parameters.WellKnownTextInEPSG3857)}
            />
          )
        }
        {
          (hasParameters && user.isAdminOrLicensing) && <OrderParameters order={order} cols={cols} />
        }
      </Row>
      <OrderLicence {...order} />
      <OrderActions order={order} onAction={onAction} />
      <OrderMessages order={order} />
    </>
  )
}
