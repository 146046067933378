import merge from 'merge'

import initialState from './initial-state'

export default (state, { type, payload }) => {
  const update = {}

  if (type === 'change') {
    update.data = merge.recursive(true, state.data, payload)
  }

  if (type === 'delete-param') {
    const deletion = merge.recursive(true, state)
    deletion.data.parameters[payload.paramKey] = null
    return deletion
  }

  if (type === 'submitted') {
    update.submitted = true
  }

  if (type === 'submit-error') {
    update.submitted = false
    update.error = payload.message || payload.errors[0]
  } else if (type === 'fetch-error') {
    update.loading = false
    update.error = payload
  } else update.error = false

  if (type === 'submit-response') {
    update.submitted = false
  }

  if (type === 'fetch-resource') {
    update.data = payload.resource
    update.loading = false
  }

  if (type === 're-routed') {
    return initialState(payload.id)
  }

  return merge.recursive(true, state, update)
}
