import React from 'react'
import ReactDOM from 'react-dom'

import 'bootstrap/dist/css/bootstrap.css'
import './index.css'

import App from './app'
import { UserProvider } from './components/user-context'

ReactDOM.render(<UserProvider><App /></UserProvider>, document.getElementById('root'))
