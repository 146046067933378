export default (id) => ({
  id,
  loading: typeof id !== 'undefined',
  data: {
    repository: 'OrderManager',
    workbench: '',
    parameters: {},
    datasetUrl: '',
    enabled: true
  },
  licences: [],
  submitted: false,
  error: false,
  message: false,
  workbenches: [
    { caption: 'Digital rivers network', value: 'DigitalRiversNetwork.fmw' },
    { caption: 'Fertiliser application maps', value: 'fertiliserApplicationMaps.fmw' },
    { caption: 'LCM 1990 vector GB', value: 'LCM_1990_vectorGB.fmw' },
    { caption: 'LCM 1990 vector N. Ireland', value: 'LCM_1990_vectorNI.fmw' },
    { caption: 'LCM 2000, 2007 and 2015 25m raster GB', value: 'LCM_2000_2007_2015_rasterGB.fmw' },
    { caption: 'LCM 2000, 2007 and 2015 25m raster N. Ireland', value: 'LCM_2000_2007_2015_rasterNI.fmw' },
    { caption: 'LCM 2017, 2018 and 2019 land parcels GB', value: 'LCM_2017_2018_2019_vectorGB.fmw' },
    { caption: 'LCM 2017, 2018 and 2019 land parcels N. Ireland', value: 'LCM_2017_2018_2019_vectorNI.fmw' },
    { caption: 'LCM GB raster datasets (data ingested in 2020)', value: 'LCM_GB_rasters_v2020.fmw' },
    { caption: 'LCM N. Ireland raster datasets (data ingested in 2020)', value: 'LCM_NI_rasters_v2020.fmw' },
    { caption: 'LCM2000 vector GB', value: 'LCM2000_Vector_GB.fmw' },
    { caption: 'LCM2000 vector N. Ireland', value: 'LCM2000_Vector_NI.fmw' },
    { caption: 'LCM2007 vector GB', value: 'LCM2007_GB_Vector.fmw' },
    { caption: 'LCM2007 vector N. Ireland', value: 'LCM2007_NI_Vector.fmw' },
    { caption: 'LCM2015 vector GB', value: 'LCM2015_GB_vector.fmw' },
    { caption: 'LCM2015 vector N. Ireland', value: 'LCM2015_NI_vector.fmw' },
    { caption: 'LCM+ hedgerows', value: 'lcmHedgerows.fmw' },
    { caption: 'Natural England - Natural capital maps', value: 'NaturalEngland_NaturalCapitalMaps.fmw' },
    { caption: 'Pesticide application map (England and Wales)', value: 'pesticideApplicationMaps_EW.fmw' },
    { caption: 'Pesticide application maps (GB)', value: 'pesticideApplicationMaps_GB.fmw' },
    { caption: 'Phytophthora infection map of Scotland', value: 'phytophthoraInfection.fmw' },
    { caption: 'Woody linear features', value: 'woodyLinearFeatures.fmw' }
  ]
})
